import { FormGroup } from "@angular/forms";
import { map } from "rxjs";

export function formValues(form: FormGroup) {
  return form.valueChanges.pipe(map(() => form.getRawValue()));
}
export function dirtyFormValues(form: FormGroup) {
  return form.valueChanges.pipe(
    map(() => {
      const dirtyValues: any = {};
      Object.keys(form.controls).forEach(key => {
        const control = form.controls[key];
        if (control.dirty) {
          dirtyValues[key] = control.value;
        }
      });
      return dirtyValues;
    })
  );
}